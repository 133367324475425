import { Breadcrumb, Button, Card, Form, Icon, Input, message } from "antd"
import { gql } from "apollo-boost"
import { Link } from "gatsby"
import React from "react"
import { Mutation } from "react-apollo"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const formItemLayoutWithLabel = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
}

const formItemLayoutWithoutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
}

const CREATE_ITEM_CATEGORY = gql`
  mutation createItemCategory($objects: [mbt_item_categories_insert_input!]!) {
    insert_mbt_item_categories(objects: $objects) {
      returning {
        name
      }
    }
  }
`

const ItemCategoryForm = Form.create()(({ form }) => (
  <Mutation mutation={CREATE_ITEM_CATEGORY}>
    {(createItemCategory, { loading }) => (
      <Form
        {...formItemLayoutWithLabel}
        onSubmit={e => {
          e.preventDefault()
          form.validateFieldsAndScroll(async (errors, objects) => {
            if (!errors) {
              try {
                await createItemCategory({
                  variables: { objects: [objects] },
                })
                form.resetFields()
                message.success("Successfully added new item category")
              } catch (err) {
                message.error("Failed to add new item category")
              }
            }
          })
        }}
      >
        <Form.Item label="Name">
          {form.getFieldDecorator("name", {
            rules: [
              { required: true, message: "Name is required", whitespace: true },
            ],
          })(<Input disabled={loading} />)}
        </Form.Item>
        <Form.Item {...formItemLayoutWithoutLabel}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!form.isFieldsTouched()}
            loading={loading}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    )}
  </Mutation>
))

export default () => (
  <Layout>
    <SEO title="Item Category" />
    <Breadcrumb style={{ margin: "16px 0" }}>
      <Breadcrumb.Item>
        <Link to="/">
          <Icon type="home" />
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to="/item-category/">Item Category</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>New</Breadcrumb.Item>
    </Breadcrumb>
    <Card>
      <ItemCategoryForm />
    </Card>
  </Layout>
)
